
































































import Vue from "vue";
import { ApiException } from "@/clients/clients";
import AppPage from "@/components/layout/page/AppPage.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "AccountVerifyView",
  components: { AppPage },
  data: () => ({
    loading: false,
    errorMessage: "",
  }),
  computed: {
    ...mapGetters("UserStore", ["verified"]),
  },
  async created() {
    this.loading = true;
    this.errorMessage = "";
    try {
      setTimeout(async () => {
        this.loading = false;
        if (this.verified) {
          await this.$router.push({ name: "Home" });
        }
      }, 500);
    } catch (e) {
      this.errorMessage = (e as ApiException).message;
    }
  },
});
